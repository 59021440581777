import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "@core/services/auth/auth.service";
import { UserService } from "@core/services/user.service";

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private userService: UserService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userInfo = this.userService.userInfo;

    if (!this.authService.isAuthorized()) {
      document.location.href = this.authService.getAuthCodeUrl(document.location.href);
      return false;
    }

    if (!userInfo || !userInfo.roles || userInfo.roles.length === 0) {
      this.navigateToNoAccess(state.url);
      return false;
    }

    return true;
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/no-access']).then(() => {
      this.location.replaceState(url);
    });
  }
}
