/** Регулярное выражения для проверки корректности IP адреса */
export const REGEXP_IP = /([0-9]{1,3}[\.]){3}[0-9]{1,3}/;

export const ACCESS_OBJECTS_KEY = "DataCollector-AccessObjects";
export const ACCESS_RIGHTS_KEY = "DataCollector-AccessRights";
export const USER_MENU_KEY = "DataCollector-UserMenuKey";
export const MENU_OPENED_KEY = "DataCollector-MenuOpenedKey";
export const USER_INFO_KEY = "DataCollector-UserInfo";
export const REDIRECT_TO_KEY = "DataCollector-RedirectTo";
export const AUTH_KEY = "DataCollector-Auth";
export const SYSTEM_PARAMS_KEY = "DataCollector-SystemParams";
export const PUBLIC_SYSTEM_PARAMS_KEY = "DataCollector-PublicSystemParams";

export const MENU_URLS = {
  g_Catalog: "/nsi/dictionaries",
  g_Role: "/admin/settings-access/roles",
  g_User: "/admin/settings-access/users",
  g_System: "/admin/parameters/system-parameters",
  g_TaskSchedule: "/admin/parameters/task-schedule",
  g_EventLog: "/admin/monitoring/event-log",
  g_Service: "/admin/maintenance/usd-services",
  g_Usd: "/devices/usd",
  g_usd_proxy_server: "/devices/usd-proxy-server",
  g_usd_update_firmware: "/devices/usd-firmware-update",
  g_usd_config: "/devices/usd-config",
  g_WebProxy: "/admin/parameters/web-proxy",
  g_Dashboard: "/admin/dashboard",
  g_Integrations: "/admin/parameters/integrations",
};

export const MENU_ICONS = {
  m_NSI: "icon-menu_nsi",
  m_Admin: "icon-menu_admin",
  m_Devices: "icon-menu_immac",
};

export const MENU_OPENED_URLS = ["/main", "/test"];

export enum GRID_ID {
  "ADMIN.USERS" = "DataCollector-admin.users",
  "ADMIN.ROLES" = "DataCollector-admin.roles",
  "ADMIN.SYSTEM_PARAMETERS" = "DataCollector-admin.system_parameters",
  "ADMIN.WEB_PROXY" = "admin.web_proxy",
  "ADMIN.WEB_PROXY_JOURNAL" = "admin.web_proxy_journal",
  "ADMIN.INTEGRATIONS" = "admin.integrations",
  "ADMIN.TASK_SCHEDULE" = "DataCollector-admin.task_schedule",
  "EVENT_LOG" = "DataCollector-event_log",
  "ADMIN.MAINTENANCE.USD_SERVICES" = "DataCollector-admin.maintenance.usd-services",
  "ADMIN.MAINTENANCE.USD_SERVICES.DEVICES" = "DataCollector-admin.maintenance.usd-services.devices",
  "DEVICES.USD" = "DataCollector-devices.usd",
  "DEVICES.USD.CONFIGURATIONS" = "DataCollector-devices.usd.configurations",
  "DEVICES.USD.CONFIGURATIONS.COMPARE" = "DataCollector-devices.usd.configurations.compare",
  "DEVICES.USD.EVENTS" = "DataCollector-devices.usd.events",
  "DEVICES.USD.CONNECTIONS" = "DataCollector-devices.usd.connections",
  "DEVICES.USD.FIRMWARE_UPDATE_JOURNAL" = "DataCollector-devices.usd.firmware-update-journal",
  "DEVICES.USD.COMMAND_QUEUE" = "DataCollector-devices.usd.command-queue",
  "DEVICES.USD.INTEGRATIONS" = "DataCollector-devices.usd.integrations",
  "DEVICES.USD_PROXY_SERVER" = "DataCollector-devices.usd-proxy-server",
  "DEVICES.USD_FIRMWARE_UPDATE" = "DataCollector-devices.usd-firmware-update",
  "DEVICES.USD_FIRMWARE_UPDATE.JOURNAL" = "DataCollector-devices.usd-firmware-update-journal",
  "DEVICES.USD.MEASURE_POINT.INSTANT_PARAMS" = "DataCollector-devices.usd.measure-point.instant-params",
  "DEVICES.USD.MEASURE_POINT.ARCHIVE_PARAMS" = "DataCollector-devices.usd.measure-point.archive-params",
  "DEVICES.USD.MEASURE_POINT.PROFILE_PARAMS" = "DataCollector-devices.usd.measure-point.profile-params",
  "DEVICES.USD_CONFIG" = "DataCollector-devices.usd-config",
}

export enum USER_ACCESS {
  "ADMIN" = "m_Admin",
  "ROLE" = "g_Role",
  "ROLE.card" = "c_Role",
  "ROLE.card_add" = "c_Role.cf_add",
  "ROLE.card_edit" = "c_Role.cf_edit",
  "ROLE.card_del" = "c_Role.cf_del",
  "USER" = "g_User",
  "USER.card" = "c_User",
  "USER.card_edit" = "c_User.cf_edit",
  "USER.grid_repl" = "g_User.gf_repl",
  "SYSTEM" = "g_System",
  "SYSTEM.card" = "c_System",
  "SYSTEM.card_edit" = "c_System.cf_edit",
  "TASKSCHEDULE" = "g_TaskSchedule",
  "TASKSCHEDULE.card" = "c_TaskSchedule",
  "TASKSCHEDULE.card_edit" = "c_TaskSchedule.cf_edit",
  "EVENT_LOG" = "g_EventLog",
  "MAINTENANCE" = "m_Service",
  "MAINTENANCE.USD_SERVICE" = "g_Service",
  "MAINTENANCE.USD_SERVICE.CARD" = "c_Service",
  "NSI_CATALOG" = "g_Catalog",
  "DEVICES" = "m_Devices",
  "DEVICES.USD" = "g_Usd",
  "DEVICES.USD.card" = "c_Usd",
  "DEVICES.USD.card_add" = "c_Usd.cf_add",
  "DEVICES.USD.card_edit" = "c_Usd.cf_edit",
  "DEVICES.USD.card_delete" = "c_Usd.cf_delete",
  "DEVICES.USD.card_remove_from_service" = "c_Service.cf_removeUsd",
  "DEVICES.USD.card_reboot" = "c_Usd.cf_reboot",
  "DEVICES.USD.card_write_tree" = "c_Usd.cf_write_tree",
  "DEVICES.USD.card_read_tree" = "c_Usd.cf_read_tree",
  "DEVICES.USD.card_read_instant_values" = "c_Usd.cf_read_instant_values",
  "DEVICES.USD.card_read_archive_values" = "c_Usd.cf_read_archive_values",
  "DEVICES.USD.card_update-firmware" = "c_Usd.cf_update_firmware",
  "DEVICES.USD.card_open_webui" = "c_Usd.cf_go_to_rweb",
  "DEVICES.MEASURE_POINT.card" = "c_Measure_Point",
  "DEVICES.MEASURE_POINT.card_read_instant_values" = "c_Measure_Point.cf_read_instant_values",
  "DEVICES.MEASURE_POINT.card_read_archive_values" = "c_Measure_Point.cf_read_archive_values",
  "DEVICES.MEASURE_POINT.create_tu_card" = "c_Measure_Point.cf_create",
  "DEVICES.MEASURE_POINT.delete_tu_card" = "c_Measure_Point.cf_delete",
  "DEVICES.DEVICE_METER.card" = "c_Device_Meter",
  "DEVICES.DEVICE_METER.card_read_instant_values" = "c_Device_Meter.cf_read_instant_values",
  "DEVICES.DEVICE_METER.card_read_archive_values" = "c_Device_Meter.cf_read_archive_value",
  "DEVICES.DEVICE_METER.create_pu_card" = "c_Device_Meter.cf_create",
  "DEVICES.DEVICE_METER.delete_pu_card" = "c_Device_Meter.cf_delete",
  // Подключение УСД к Proxy server
  "DEVICES.USD_PROXY_SERVER" = "g_usd_proxy_server",
  "DEVICES.USD_PROXY_SERVER.ADD_USD" = "g_usd_proxy_server.gf_addUsd",
  "DEVICES.USD_PROXY_SERVER.OPEN_SSH" = "g_usd_proxy_server.gf_openUsd_SSH",
  // Обновление ПО УСД
  "DEVICES.USD_FIRMWARE_UPDATE" = "g_usd_update_firmware",
  "DEVICES.USD_FIRMWARE_UPDATE.CARD" = "c_Usd_Update_Firmware",
  "DEVICES.USD_FIRMWARE_UPDATE.CARD.CREATE" = "c_Usd_Update_Firmware.cf_create",
  "DEVICES.USD_FIRMWARE_UPDATE.CARD.DELETE" = "c_Usd_Update_Firmware.cf_delete",
  "DEVICES.USD_FIRMWARE_UPDATE.CARD.UPDATE" = "c_Usd_Update_Firmware.cf_update",
  // Конфигурирование УСД
  "DEVICES.USD_CONFIG" = "g_usd_config",
  "DEVICES.USD_CONFIG.EDIT" = "g_usd_config.gf_Edit",
  // Веб прокси
  "ADMIN.WEB_PROXY" = "g_WebProxy",
  "ADMIN.WEB_PROXY.CARD" = "c_WebProxy",
  "ADMIN.WEB_PROXY.CARD.JOURNAL" = "c_WebProxy.ct_Journal",
  "ADMIN.WEB_PROXY.CARD.ADD" = "c_WebProxy.cf_add",
  "ADMIN.WEB_PROXY.CARD.EDIT" = "c_WebProxy.cf_edit",
  "ADMIN.WEB_PROXY.CARD.DELETE" = "c_WebProxy.cf_delete",
  "ADMIN.WEB_PROXY.CARD.ADD_ROLE" = "c_WebProxy.cf_add_role",
  "ADMIN.WEB_PROXY.CARD.DELETE_ROLE" = "c_WebProxy.cf_delete_role",
  "ADMIN.DASHBOARD" = "g_Dashboard",
  "ADMIN.INTEGRATIONS" = "g_Integrations",
  "ADMIN.INTEGRATIONS.ADD" = "g_Integrations.gf_Add",
  "ADMIN.INTEGRATIONS.EDIT" = "g_Integrations.gf_Edit",
  "ADMIN.INTEGRATIONS.CARD" = "c_Integration",
  "ADMIN.INTEGRATIONS.CARD.DELETE" = "c_Integration.cf_delete",
  "ADMIN.INTEGRATIONS.CARD.CLEAR_DATA" = "c_Integration.cf_clear_data",
}

export enum RoleType {
  COMMON = "COMMON",
  PERSONAL = "PERSONAL",
  NO_ROLE = "NO_ROLE",
}

export enum TaskScheduleType {
  DATABASE = "DATABASE",
  BACKEND = "BACKEND",
}

export enum Microservice {
  MAIN = "WS_WEB_BACKEND",
  MAIN_DC = "MS_DATA_COLLECTOR_MANAGER",
}

export enum DeviceMeterTypeCode {
  HEAT = "HEAT", // Тепловычислитель
  EE = "EE", // Счетчик электроэнергии
  IMPULSE = "IMPULSE", // Расходомер импульсный ХВС или ГВС
  GAS = "GAS", // Расходомер Газа
}

export enum DeviceMeterStateIcon {
  WORKING = "icon-good", // Активен
  ARCHIVE = "icon-in_archive", // Законсервирован
}

export enum EUsdServicesStatusIcon {
  ACTIVE = "icon-good",
  SHUTDOWN = "icon-not_work",
  WAIT_SHUTDOWN = "icon-dismantled_state",
  UNKNOWN = "icon-no_data",
  RECONFIGURE = "icon-service",
}

export enum EUsdStateIcon {
  SERVICED = "icon-good",
  NOT_SERVICED = "icon-not_work",
  WAIT = "icon-calendar_time",
}

export enum EUsdStateCode {
  SERVICED = "SERVICED",
  NOT_SERVICED = "NOT_SERVICED",
  WAIT = "WAIT",
}

export enum RereadDateDeviceType {
  USD = "USD",
  PU = "PU",
  TU = "TU",
}

export enum EWebProxyType {
  INTERNAL_RESOURCE = "INTERNAL_RESOURCE",
  USD_HTTP = "USD_HTTP",
}
