import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { share } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  IDictionaryBody,
  IDictionaryParams,
  ISelectItem
} from "ngx-strong-frontend-lib/interfaces";
import { IUiObjectDictionaryParams } from "@core/interfaces/dictionary";
import { ITuParamsDictionaryParams } from "@app/core/interfaces/tu";

@Injectable({
  providedIn: 'root'
})
export class DictionaryApiService {

  constructor(private httpClient: HttpClient) { }

  // Справочник "Тип ресурса" (список)
  public getResourceTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/resource-type`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Роли пользователя"
  public getRolesDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/user-role`, params
    ).pipe(share());
  }

  // Справочник "Пользователи"
  public getUserDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/user`, params
    ).pipe(share());
  }

  // Справочник "Тип события"
  public getEventTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/event-type`, params
    ).pipe(share());
  }

  // Справочник "Категория события"
  public getEventCategoryDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/event-category`, params
    ).pipe(share());
  }

  // Справочник "Тип события" для микросервисов
  public getEventTypeDictionaryForEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.httpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/event/log/dict/${remoteService}/type`
    ).pipe(share());
  }

  // Справочник "Категория события" для микросервисов
  public getEventCategoryDictionaryForEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.httpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/event/log/dict/${remoteService}/category`
    ).pipe(share());
  }

  // Справочник "Элементы UI"
  public getUiObjectDictionary(params: IUiObjectDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/ui-object`, params
    ).pipe(share());
  }

  // Справочник "Вид оборудования" (список)
  public getDeviceTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/device-type`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Тип драйвера УСД" (список)
  public getUsdDriverTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-driver-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип УСД" (список)
  public getUsdDeviceTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-device-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Тип модели УСД
   * @param codes Коды Типа УСД
   */
  public getUsdModelTypeList(codes?: string[]): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-model-type`,
      { codes }
    ).pipe(share());
  }

  /**
   * Справочник Состояние сервисаа-клиента УСД
   */
  public getUsdServiceStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/nsi-service-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник идентификаторов УСД СУ
   */
  public getUsdMidLevelIdsDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/usd/dict/ids`,
      params
    ).pipe(share());
  }

  /**
   * Справочник идентификаторов УСД ВУ
   */
  public getUsdHighLevelIdsDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/usd/dict/ids/ext`,
      params
    ).pipe(share());
  }

  /**
   * Справочник "Тип события от УСД"
   */
  public getUsdEventTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-event-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Тип события УСД
   * @param params параметры
   */
  public getUsdEventTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/usd-event-type`,
      params
    ).pipe(share());
  }

  /**
   * Справочник "Подтип события от УСД"
   */
  public getUsdEventTypeDetailList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-event-type-detail`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Подтип события УСД
   * @param params параметры
   */
  public getUsdEventTypeDetailDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/usd-event-type-detail`,
      params
    ).pipe(share());
  }

  /**
   * Справочник Параметры учета ТУ (мгновенные)
   * @param payload параметры
   */
  public getMeasurePointInstantParamsDictionary(payload: ITuParamsDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/measure-point/dict/params/instant`,
      payload
    ).pipe(share());
  }

  /**
   * Справочник Параметры учета ТУ (архивные)
   * @param payload параметры
   */
  public getMeasurePointArchiveParamsDictionary(payload: ITuParamsDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/measure-point/dict/params/archive`,
      payload
    ).pipe(share());
  }

  /**
   * Справочник Параметры учета ТУ (профиль мощности)
   * @param payload параметры
   */
  public getMeasurePointProfileParamsDictionary(payload: ITuParamsDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/measure-point/dict/params/profile`,
      payload
    ).pipe(share());
  }

  /**
   * Справочник «Протоколы взаимодействия с УСД»
   * @returns
   */
  public getUsdInteractionProtocolsList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-interaction-protocol`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник доступных для усд обновлений прошивок.
   * @param params параметры.
   */
  public getUsdAvailableFirmwareUpdateDictionary(usdId: number, params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/${usdId}/usd-firmware`,
      params
    ).pipe(share());
  }

  /**
   * Справочник типов интеграций
   * @returns
   */
  public getIntegrationsTypesList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/integration-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник интеграций, доступных для добавления к УСД
   * @param usdId ИД УСД
   */
  public getUsdAvailableIntegrationsList(usdId: number): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/${usdId}/available-integrations`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник типов пакетов интеграций
   */
  public getIntegrationsPackageTypesList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/integration-packet-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Состояния обслуживания УСД экземпляром сервиса
   */
  public getUsdStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/nsi-usd-state`,
      {}
    ).pipe(share());
  }


  /**
   * Справочник "Модели оборудования ПУ"
   */
  public getPuModelList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/meter-model-type`,
      {}
    ).pipe(share());
  }
}
