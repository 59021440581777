<div class="files-downloads">
  @for (item of downloads | async; track item) {
    <lib-download-progress
      [download]="item">
    </lib-download-progress>
  }
</div>
@if (!hideHeader) {
  <app-header></app-header>
}
<lib-lazy-module-loader></lib-lazy-module-loader>
<router-outlet></router-outlet>
