import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseAuthService } from 'ngx-strong-frontend-lib/services/auth';
import { LocalStorageService } from 'ngx-strong-frontend-lib/services/local-storage';
import { ITokenResponse } from 'ngx-strong-frontend-lib/interfaces';

@Injectable()
export abstract class AuthService
  extends BaseAuthService {

  constructor(
    protected localStorageService: LocalStorageService
  ) {
    super(localStorageService);
  }
  /**
   * Обновить токены
   * @param authUrl урл ПАА
   */
  abstract refreshToken(authUrl: string): Observable<ITokenResponse>;
  /**
   * Получить УРЛ аутентификации в ПАА
   * @param currentUrl Текущий урл
   */
  abstract getAuthCodeUrl(currentUrl: string): string;
  /**
   * Получить токены на основе кода из ПАА
   * @param code Код
   */
  abstract getTokenFromCode(code: string): Observable<ITokenResponse>;
}
