<div class="headerClass">
  <div class="header-left">
    <lib-button class="header-menu"
                [buttonStyles]="[EButtonStyle.CONTAINER]"
                (OnClick)="toggleMenu(true)">
      <span class="font-icons icon-menu"></span>
    </lib-button>
    <a [href]="homeUrl"
       class="header-home">
      <span class="font-icons icon-home"></span>
    </a>
    <span class="font-icons icon-forward-long"></span>
    <a [routerLink]="mainUrl"
       class="header-logo">
      <img src="assets/images/DataHarvest_logo_smol.svg" />
    </a>
    <lib-breadcrums [breadcrumbs]="breadcrumbs$ | async"
                    class="header-breadcrums"></lib-breadcrums>
  </div>
  <div class="header-right">
    <div class="hedaer-right-item">
      <lib-button class="header-docs"
                  [buttonStyles]="[EButtonStyle.ICON]"
                  icon="icon-guide_header"
                  iconSize="25"
                  (OnClick)="downloadGuide()">
      </lib-button>
    </div>
    <div class="hedaer-right-item"
         #supportBalloonAnchor>
      <lib-button #buttonSupport
                  class="header-support"
                  [buttonStyles]="[EButtonStyle.ICON]"
                  icon="icon-support_header"
                  iconSize="21"
                  focusTrapRestore
                  #FTRSupport="focusTrapRestore"
                  (OnClick)="setSupportBalloonOpened(true)"
                  (clickOutside)="setSupportBalloonOpened(false)"
                  [clickOutsideEnabled]="showSupportBalloon"
                  exclude="div.header-support-balloon"
                  [excludeBeforeClick]="true">
      </lib-button>
    </div>
    <div class="hedaer-right-item"
         #userBalloonAnchor>
      <lib-button #buttonUser
                  class="header-user"
                  [buttonStyles]="[EButtonStyle.ICON]"
                  icon="icon-user_logo"
                  iconSize="25"
                  focusTrapRestore
                  #FTRUser="focusTrapRestore"
                  (OnClick)="setUserBalloonOpened(true)"
                  (clickOutside)="setUserBalloonOpened(false)"
                  [clickOutsideEnabled]="showUserBalloon"
                  exclude="div.header-user-balloon"
                  [excludeBeforeClick]="true">
      </lib-button>
    </div>
    <!-- <span class="header-notification font-icons icon-notification"
    [class.unread]="hasUnreadNotifications">
    <span class="path1"></span>
    <span class="path2"></span>
  </span> -->
  </div>
</div>

<lib-menu></lib-menu>

<lib-popup-container-template [popupContainerTarget]="supportBalloonAnchor"
                              [popupContainerShow]="showSupportBalloon"
                              popupContainerOpenDirection="bottom right"
                              [popupContainerOffsetRelative]="[0,10]"
                              [popupContainerAutofocusEvent]="buttonSupport.OnClick | async">
  <div class="header-support-balloon"
       focusTrap
       (keydown.Escape)="setSupportBalloonOpened(false); FTRSupport.restoreTabIndex()">
    <div class="header-support-balloon-item">
      <span class="font-icons icon-phone"></span>
      <span class="text">{{supportPhone}}</span>
    </div>
    <div class="header-support-balloon-item">
      <span class="font-icons icon-mail"></span>
      <a href="mailTo:{{supportEmail}}"
         target="_blank"
         class="outer-href">{{supportEmail}}</a>
    </div>
  </div>
</lib-popup-container-template>

<lib-popup-container-template [popupContainerTarget]="userBalloonAnchor"
                              [popupContainerShow]="showUserBalloon"
                              popupContainerOpenDirection="bottom right"
                              [popupContainerOffsetRelative]="[0,10]"
                              [popupContainerAutofocusEvent]="buttonUser.OnClick | async">
  <div class="header-user-balloon"
       focusTrap
       (keydown.Escape)="setUserBalloonOpened(false); FTRUser.restoreTabIndex()">
    @if (user?.name) {
    <div class="header-user-balloon-item">
      <span class="text user-name"
            [innerHTML]="user.name"></span>
    </div>
    }
    @if (user?.phoneNumber) {
    <div class="header-user-balloon-item">
      <span class="font-icons icon-phone"></span>
      <span class="text"
            [innerHTML]="user.phoneNumber"></span>
    </div>
    }
    @if (user?.email) {
    <div class="header-user-balloon-item">
      <span class="font-icons icon-mail"></span>
      <span class="text"
            [innerHTML]="user.email"></span>
    </div>
    }
    <lib-button class="header-user-balloon-item"
                [title]="'GENERAL.EXIT' | translate"
                (OnClick)="exit()">
    </lib-button>
  </div>
</lib-popup-container-template>