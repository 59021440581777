import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@core/services/user.service";
import { IUserInfo } from "@core/interfaces/user";
import { SystemParamsService } from "@core/services/system-params.service";
import { Subscription } from "rxjs";
import { GuideApiService } from "@core/services/api/guide-api.service";
import { MenuService } from 'ngx-strong-frontend-lib/services/menu';
import { OpenModalService } from 'ngx-strong-frontend-lib/services/open-modal';
import { BreadcrumbsService } from 'ngx-strong-frontend-lib/services/breadcrumbs';
import { SYSTEM_PARAMETER_NAME } from 'ngx-strong-frontend-lib/enums';
import { ConfirmModalComponent } from 'ngx-strong-frontend-lib/components/confirm-modal';
import { IKeyValuePairs } from 'ngx-strong-frontend-lib/interfaces';
import { EButtonStyle } from 'ngx-strong-frontend-lib/components/button';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public readonly EButtonStyle = EButtonStyle;
  public user: IUserInfo;
  public supportPhone: string;
  public supportEmail: string;
  public mainUrl: string = '/main';
  public homeUrl: string = '';
  // public hasUnreadNotifications: boolean = true;
  public showSupportBalloon: boolean = false;
  public showUserBalloon: boolean = false;
  private readonly exitTitles: IKeyValuePairs = this.translateService.instant(['GENERAL.LOGOUT_QUESTION', 'GENERAL.EXIT']);
  private publicSystemParamsSubscription: Subscription;
  private userInfoSubsctiption: Subscription;

  public breadcrumbs$ = this.breadcrumbsService.observeBreadcrumbs({
    renderNeighbours: 'last',
    accessObjectsKey: 'userAccessObjects'
  })

  constructor(
    private menuService: MenuService,
    private modalService: OpenModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private systemParamsService: SystemParamsService,
    private guideApiService: GuideApiService,
    private breadcrumbsService: BreadcrumbsService
  ) {
  }

  ngOnInit() {
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.supportPhone = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] : '';
        this.supportEmail = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] : '';
        this.homeUrl = systemParams && systemParams[SYSTEM_PARAMETER_NAME.AUTH_URL]
          ? `${systemParams[SYSTEM_PARAMETER_NAME.AUTH_URL]}/home` : '';
      });
    this.userInfoSubsctiption = this.userService.getUserInfo().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
    this.userInfoSubsctiption.unsubscribe();
  }

  public toggleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public downloadGuide() {
    this.guideApiService.getGuideFile();
  }

  public setSupportBalloonOpened(value: boolean) {
    this.showSupportBalloon = value;
  }

  public setUserBalloonOpened(value: boolean) {
    this.showUserBalloon = value;
  }

  public exit() {
    this.setUserBalloonOpened(false);
    this.modalService.show({
      component: ConfirmModalComponent,
      data: {
        question: this.exitTitles['GENERAL.LOGOUT_QUESTION'],
        applyTitle: this.exitTitles['GENERAL.EXIT'],
        onApply: () => {
          this.userService.logout();
        }
      },
      options: {
        centered: true,
        windowClass: 'modal-confirm',
      }
    });
  }
}
