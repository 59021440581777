import { Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Сервис динамического импорта файлов переводов.
 * При динамическом импорте и prod сборке webpack добавляет к файлам хеш.
 * Решение проблемы обновления закешированных браузером файлов при обновлении стенда.
 */
@Injectable()
export class DynamicImportTranslationLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return from(
            import(`../../../assets/i18n/${lang}.json`)
        )
            .pipe(map(res => res.default));
    }
}