import { Injectable } from '@angular/core';
import { AuthService } from "@core/services/auth/auth.service";
import { UserApiService } from "@core/services/api/user-api.service";
import { IAccessObjects, IAccessTree, IUserInfo } from "@core/interfaces/user";
import { LocalStorageService } from "ngx-strong-frontend-lib/services/local-storage";
import { MenuService } from "ngx-strong-frontend-lib/services/menu";
import { VisibilityService } from "ngx-strong-frontend-lib/services/visibility";
import { BehaviorSubject, Observable } from "rxjs";
import { AUTH_KEY, USER_INFO_KEY } from "@app/app.enums";
import { ACCESS_TREE_OBJECT_TYPE, SYSTEM_PARAMETER_NAME } from 'ngx-strong-frontend-lib/enums';
import { SystemParamsService } from './system-params.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfoKey: string = USER_INFO_KEY;
  private _userInfo: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(null);

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private userApiService: UserApiService,
    private menuService: MenuService,
    private visibilityService: VisibilityService,
    private systemParamsService: SystemParamsService
  ) {
    this._userInfo.next(this.localStorageService.getObjectByName(this.userInfoKey));
    /**
     * bugfix DC-252
     * Запрос отправляется каждый раз при загрузке приложения для "авторизованного пользователя"
     */
    if (this.authService.isAuthorized()) {
      this.userApiService.getUserInfo().subscribe(
        (userInfo: IUserInfo) => {
          this.userInfo = userInfo;
        });
    }
  }

  get userInfo(): IUserInfo {
    return this._userInfo.getValue();
  }

  set userInfo(userInfo: IUserInfo) {
    this._userInfo.next(userInfo);
    this.localStorageService.setObjectByName(this.userInfoKey, userInfo);
  }

  public setAccessObjects(accessObjects: IAccessObjects, accessTree: IAccessTree) {
    this.visibilityService.setAccessObjects([
      ...accessObjects.uiObjectsCodes,
      ...this.getGridsAccessObjects(accessTree)
    ])
    this.menuService.setUserMenuWithPrepare(accessTree?.uiObjects);
  }

  private getGridsAccessObjects(accessTree: IAccessTree) {
    return accessTree?.uiObjects
      ?.filter(item => item.objectType === ACCESS_TREE_OBJECT_TYPE.GRID)
      ?.map(item => item.code) ?? [];
  }

  public getUserInfo(): Observable<IUserInfo> {
    return this._userInfo.asObservable();
  }

  public logout() {
    this.clearUserSession();
    const authUrl: string = this.localStorageService.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
    this.authService.logout().subscribe(
      responce => {
        if (authUrl) {
          document.location.href = `${authUrl}/exit`;
        }
      },
      error => {
        if (authUrl) {
          document.location.href = `${authUrl}/auth`;
        }
      }
    );
  }

  public clearUserSession() {
    this.userInfo = null;
    this.localStorageService.removeObjectByName(AUTH_KEY);
    this.menuService.setUserMenu(null);
    this.systemParamsService.clearSystemParameters();
    this.visibilityService.clearAccessObjects();
  }
}
